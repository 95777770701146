<template>
	<v-container>
		<AddEmployee />
		<SendMails isForEmployes/>
		<RecruitmentForm  isForEmployes/>
		<EmployeesTable/>
	</v-container>
</template>

<script>
import EmployeesTable from '@/components/templates/EmployeesTable.vue';
import AddEmployee from '@/components/molecules/AddEmployee.vue';
import SendMails from '@/components/molecules/SendMails.vue';
import RecruitmentForm from '@/components/molecules/RecruitmentForm.vue';

export default {
	components: {
		EmployeesTable,
		AddEmployee,
		SendMails,
		RecruitmentForm
	}
}
</script>